import React, { Component } from "react";
import axios from "@/axios";
import { applyVueInReact, withVueRouter } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";


class HealthCare extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      labList: [],
      district: [],
      service: [],
      selectedDistrict: "",
      selectedService: "",
      loading: true,
    };
  }

  componentDidMount() {
    //retriving lablist
    this.fetchLabList({});

    //retriving district list
    axios.post("api/v2/services/healtCare/getDistrict").then((data) => {
      this.setState({
        district:
          data.data.response[0].GetDistrictResponse[0].GetDistrictResult[0]
            .Districts[0].string,
      });
    });

    //retriving category list
    axios
      .get("api/v2/services/healtCare/getCategory")
      .then((data) => {
        const uniqueServices = new Set();
        data.data.response[0].GetCategoryResponse[0].GetCategoryResult[0].Categories[0].Category.forEach(
          (category) => {
            uniqueServices.add(category.Service[0]);
          }
        );
        return uniqueServices;
      })
      .then((data) => {
        this.setState({ service: [...data] });
      });

    this.setState({
      loading: false,
    });
  }

  fetchLabList = async (payload) => {
    this.setState({
      loading: true,
    });
    const data = await axios.post(
      "api/v2/services/healtCare/getLabList",
      payload
    );
    this.setState({
      labList:
        data.data.response[0].GetLabListResponse[0].GetLabListResult[0].Labs[0]
          .Lab,
    });
    this.setState({
      loading: false,
    });
  };

  handleDistrictChange = (event) => {
    this.setState(
      {
        selectedDistrict: event.target.value,
      },
      () => {
        this.fetchLabList({
          District: this.state.selectedDistrict,
          Service: this.state.selectedService,
        });
      }
    );
  };

  handleServiceChange = (event) => {
    this.setState(
      {
        selectedService: event.target.value,
      },
      () => {
        this.fetchLabList({
          District: this.state.selectedDistrict,
          Service: this.state.selectedService,
        });
      }
    );
  };

  labClicked = (labId) => {
    this.props.$vueRouter.push(`/health-care/${labId}`);
  };

  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h4> Health Care</h4>
            {/* Filter division */}
            <div className="container-fluid bg-light p-2">
              <div className="mb-1 text-muted text-right">Filter Options</div>
              <div className="row" style={{ margin: "0px" }}>
                <div className="col m-1">
                  <select
                    className="custom-select"
                    name="district"
                    id="distric"
                    onChange={this.handleDistrictChange}
                  >
                    <option value="" selected>
                      {" "}
                      All Districts
                    </option>
                    {this.state.district.map((val) => {
                      return <option value={val}>{val}</option>;
                    })}
                  </select>
                </div>

                <div className="col m-1">
                  <select
                    name="service"
                    id="service"
                    className="custom-select"
                    onChange={this.handleServiceChange}
                  >
                    <option value="" selected>
                      {" "}
                      All Service
                    </option>
                    {this.state.service.map((val) => {
                      return <option value={val}>{val}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>

            {/* List division */}
            <div className="row mt-5">
              {this.state.labList &&
                this.state.labList.length > 0 &&
                this.state.labList.map((lab) => {
                  return (
                    <div className="col-6 col-md-4">
                      <div
                        className="card cursor-pointer"
                        onClick={() => {
                          this.labClicked(lab.LabId[0]);
                        }}
                      >
                        <div className="card-body" style={{ height: "150px" }}>
                          <div className="icon" style={{ height: "100%" }}>
                            <img
                              src={lab.Logo[0]}
                              alt={lab.Name[0]}
                              style={{ maxWidth: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                        <div
                          className="card-footer"
                          style={{
                            height: "200px",
                          }}
                        >
                          <div className="mt-2">
                            <h4>{lab.Name[0]}</h4>
                          </div>
                          <div className="mt-2">
                            <>Address : {lab.Address[0]}</>
                          </div>
                          <div className="mt-2">
                            <>Phone : {lab.Phone[0]}</>
                          </div>
                          <div className="mt-2">
                            <>Email : {lab.Email[0]}</>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default withVueRouter(HealthCare);
